import React from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-GB';
import { getLocale } from 'utils/lib/getLocale';
import 'react-datepicker/dist/react-datepicker.css';
import { Field } from './Field';
import { formatDateValue } from '../../utils/lib/formatDateValue';
import { useStores } from '../../stores';

registerLocale('ru', ru);
registerLocale('en', en);

export const DateTimeField = ({ fieldProps = {}, ...calendarProps }: any): JSX.Element => {
  const { selected, dateFormat, placeholderText, selectsRange, startDate, endDate } = calendarProps || {};
  const { usersStore } = useStores();
  let locale = getLocale(usersStore?.settings?.lang_id);
  return (
    <Field {...fieldProps}>
      <DatePicker
        locale={locale || 'ru'}
        calendarClassName="custom-calendar"
        timeCaption=""
        isClearable
        customInput={
          <div className="relative">
            <div
              className={`datepicker flex w-full justify-between form-control pr-4 items-center h-full`}
              id="datepicker"
            >
              {!selectsRange && (
                <span className={`${selected ? '' : 'opacity-50'}`}>
                  {formatDateValue(selected, { format: dateFormat, isUTC: false }) || placeholderText}
                </span>
              )}
              {selectsRange && (startDate || endDate) ? (
                <span>
                  {startDate && formatDateValue(startDate, { format: dateFormat, isUTC: false })} -{' '}
                  {endDate && formatDateValue(endDate, { format: dateFormat, isUTC: false })}
                </span>
              ) : null}
              {selectsRange && !startDate && !endDate ? <span className="opacity-50">{placeholderText}</span> : null}
              <div className="flex">
                <svg
                  className="w-4.5 h-4.5 text-black text-opacity-40 pointer-events-none"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M20.24 3.85h-1.71v1.87c0 .8-.67 1.45-1.5 1.45-.82 0-1.5-.65-1.5-1.45V3.85H8.5v1.87c0 .8-.68 1.45-1.5 1.45-.83 0-1.5-.65-1.5-1.45V3.85H3.76c-.98 0-1.76.76-1.76 1.7V20.3c0 .94.78 1.7 1.76 1.7h16.48c.98 0 1.76-.76 1.76-1.7V5.55c0-.94-.78-1.7-1.76-1.7ZM21 20.3c0 .4-.34.71-.76.71H3.76a.72.72 0 0 1-.74-.71V8.94H21V20.3ZM7 6.42c.41 0 .74-.32.74-.72V2.72c0-.4-.33-.72-.74-.72a.72.72 0 0 0-.74.72V5.7c0 .4.33.72.74.72Zm10.04 0c.41 0 .74-.32.74-.72V2.72c0-.4-.33-.72-.74-.72a.72.72 0 0 0-.73.72V5.7c.02.4.34.72.73.72Z"></path>
                </svg>
              </div>
            </div>
          </div>
        }
        {...calendarProps}
      />
    </Field>
  );
};
