import React, { useEffect, useState } from 'react';
import MaskedInput from './MaskedInput';
import { placeholders, countryTranslation, getCountryCca2 } from '../../utils/lib/formatPhone';
import IntlTelInput from 'intl-tel-input/react';
import 'intl-tel-input/styles';
import { useDebouncedEffect } from '../../hooks/useDebouncedEffect';

interface MaskedInputProps {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onChange: (value: string) => void;
  onFocus: () => void;
  onBlur?: (name?: string, placeholder?: string, val?: string) => void;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  id?: string;
  name?: string;
  disabled?: boolean;
  className?: string;
}

const PhoneInput = ({ inputProps, onChange, defaultValue, ...rest }: MaskedInputProps): JSX.Element => {
  const [val, setVal] = useState(rest.value);
  const [country, setCountry] = useState(getCountryCca2(defaultValue?.replace(/\D/g, '') || '').toLowerCase() || 'ru');

  useDebouncedEffect(
    () => {
      if (rest && val !== rest.value && onChange) {
        // @ts-ignore
        onChange(val);
      }
    },
    [val],
    1000,
  );

  useEffect(() => {
    if (defaultValue !== val || defaultValue === rest.value) {
      const selectedCountry = getCountryCca2(defaultValue?.replace(/\D/g, '') || '').toLowerCase() || 'ru';
      const len = String(placeholders[selectedCountry.toLowerCase()]).replace(/[^0-9]/gi, '').length;
      if (selectedCountry && (!defaultValue || defaultValue.replace(/[^0-9]/gi, '').length === len)) {
        setCountry('');
        setTimeout(() => {
          setCountry(selectedCountry);
        }, 100);
      }
      setVal(defaultValue);
    }
  }, [defaultValue]);
  const onChangeCountry = (c) => {
    if (c && country && c !== country) {
      setCountry(c);
      setVal('');
    }
  };
  const onBlur = () => {
    if (rest.onBlur) rest.onBlur(rest.name, placeholders[country] && placeholders[country].replace(/\\/gi, ''), val);
  };
  const onPaste = (e) => {
    const currentValue = e?.clipboardData?.getData('Text');
    const len = String(placeholders[country] || '').replace(/[^0-9]/gi, '').length;
    if (len && len === currentValue?.length) {
      e.preventDefault();
      setVal(currentValue);
    }
  };
  if (!country) return <div className="phone-input__container"></div>;
  return (
    <div className={`phone-input__container ${rest.disabled ? 'disabled' : ''}`}>
      <div className="phone-input__country">
        <IntlTelInput
          onChangeCountry={onChangeCountry}
          initOptions={{
            initialCountry: country,
            separateDialCode: true,
            autoPlaceholder: 'polite',
            i18n: countryTranslation,
          }}
          inputProps={{
            style: {
              display: 'none',
            },
          }}
        />
      </div>
      <MaskedInput
        placeholder={placeholders[country] && placeholders[country].replace(/\\/gi, '')}
        mask={placeholders[country]}
        className="form-control form-control--lg phone-input"
        onChange={(e) => setVal(e.target.value)}
        value={String(val).replace(/[^0-9]/gi, '')}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onPaste={onPaste}
      />
    </div>
  );
};

export default PhoneInput;
