import phoneData from './intl-phones.json';
import { PhoneNumberFormat, PhoneNumberUtil, RegionCode } from 'google-libphonenumber';
import { Iso3166Alpha2Code } from 'iso-3166-ts';

const phoneUtil = PhoneNumberUtil.getInstance();

export const placeholders = phoneData['phone-masks'];
export const countryTranslation = phoneData.translations;
export const getCountryCode = (phone: string) => {
  const [selectedCountry] = Object.keys(placeholders)
    .reduce((res: { name: string; value: string }[], c) => [...res, { name: c, value: placeholders[c] }], [])
    .map((t) => ({
      name: t.name,
      value: (t.value || '')
        .replace(/\\9/gi, 'D')
        .replace(/9/gi, '_')
        .replace(/D/gi, '9')
        .replace(/[^0-9]/gi, ''),
    }))
    .filter((c) => String(phone).indexOf(c.value) === 0);
  return selectedCountry;
};
export const getCountryCca2 = (phone: string) => {
  const country = getCountryCode(phone);
  return (country ? country.name.toUpperCase() : 'RU') as Iso3166Alpha2Code;
};

export const formatPerformerPhone = (performerPhone: string = ''): string => {
  const commaInd = performerPhone.indexOf(',');
  if (commaInd > 0) {
    const phone = performerPhone.substring(0, commaInd);
    const added_number = performerPhone.substring(commaInd, performerPhone.length);
    return `${formatPhone(phone)}${added_number}`;
  }
  return formatPhone(performerPhone);
};

export const formatPhone = (phone: string, isInternational: boolean = true): string => {
  const _phone = '+' + (phone || '').replace(/[^0-9]/gi, '');
  if (!_phone) return '';
  if (isInternational) {
    let regionCode: undefined | RegionCode;
    try {
      const numberProto = phoneUtil.parse(_phone, '');
      regionCode = phoneUtil.getRegionCodeForNumber(numberProto);
    } catch (err) {
      console.log(err);
    }

    // Temporarily hide custom search of the region code

    // const [selectedCountry] = Object.keys(placeholders)
    //   .reduce((res: { name: string; value: string }[], c) => [...res, { name: c, value: placeholders[c] }], [])
    //   .map((t) => {
    //     console.log({ t }); return ({
    //       name: t.name,
    //       value: (t.value || '')
    //         .replace(/\\9/gi, 'D')
    //         .replace(/9/gi, '_')
    //         .replace(/[^0-9]/gi, ''),
    //     })
    //   })
    //   .filter((c) => { console.log(c); return String(_phone).indexOf(c.value) === 0 });
    // regionCode = selectedCountry.name.toUpperCase() as RegionCode

    if (regionCode) {
      try {
        const parsedNumber = phoneUtil.parseAndKeepRawInput(_phone, regionCode);
        return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
      } catch (e) {
        return _phone;
      }
    }
    return _phone;
  } else {
    const [number, additionalNum] = phone?.replace(/,,/g, ',').split(',');
    const phoneNum: string = number?.replace(/\D/g, '');
    if (!phoneNum) return '';
    return `+${phoneNum?.slice(0, 1)} (${phoneNum?.slice(1, 4)}) ${phoneNum?.slice(4, 7)}-${phoneNum?.slice(7, 9)}-${phoneNum?.slice(9, 11)}${additionalNum ? `, ${additionalNum}` : ''}`;
  }
};
