import { PhoneNumberUtil } from 'google-libphonenumber';
import { Iso3166Alpha2Code } from 'iso-3166-ts';
import { getCountryCca2 } from './formatPhone';

const phoneUtil = PhoneNumberUtil.getInstance();

export const isValidPhone = (phone: string, countryCode?: Iso3166Alpha2Code): boolean => {
  if (!countryCode) countryCode = getCountryCca2(phone);
  if (!phone?.trim()) return false;
  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phone, countryCode);
    return phoneUtil.isValidNumber(parsedNumber);
  } catch (e) {
    return false;
  }
};
