import { isArray } from 'lodash';
import moment from 'moment';

type FormatOptions = {
  format?: string;
  separotor?: string;
  offset?: number;
  isUTC?: boolean;
};

export const formatDateValue = (value: Date | Date[] | number, options: FormatOptions = {}): string => {
  if (typeof value === 'string') {
    return value;
  }
  const { isUTC = true } = options;
  const opt = {
    format: 'DD.MM.YY',
    separotor: ' - ',
    ...options,
  };
  if (!value) return '';
  if (value instanceof Date) {
    const date = isUTC ? moment.utc(value) : moment(value);
    return date.format(opt.format) || '';
  } else if (typeof value === 'number') {
    const offset = (opt.offset || 0) < 60 ? (opt.offset || 0) * 60 : opt.offset || 0;
    const date = isUTC ? moment.utc((value || 0) * 1000) : moment((value || 0) * 1000);
    return date.utcOffset(offset).format(opt.format) || '';
  } else if (isArray(value)) {
    const start = value[0] ? moment.utc(value[0]).format(opt.format) : '';
    const end = value[1] ? moment.utc(value[1]).format(opt.format) : '';
    return `${start}${start && end ? opt.separotor : ''}${end}`;
  }
  return new Date(value)?.toString();
};
